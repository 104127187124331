import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';
import Link from '@shared/ui/navigation/Link';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import IconWithText from '@shared/ui/display/IconWIthText';
import { ErrorIcon } from '@shared/ui/sidebars/icons';
import { StepContainer } from '@shared/ui/sidebars/shared';
import Button from '@shared/ui/buttons/Button';
import { BodyMedium } from '@src/components/styled/Typography';
import usePortfolioCreate from '../usePortfolioCreate';
const ErrorDescription = styled(BodyMedium)(props => ({
    maxWidth: 400,
    textAlign: 'center',
    marginTop: props.theme.spacing_sizes.m,
    marginBottom: props.theme.spacing_sizes.l,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
}));
const ErrorHint = styled(ErrorDescription)(() => ({
    marginBottom: 0,
}));
const CreationError = ({ handleClose }) => {
    const { t } = useTranslation();
    const { goToNextStep } = usePortfolioCreate({ handleClose });
    return (_jsx(SidebarInner, { cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepContainer, { content: (_jsxs(_Fragment, { children: [_jsx(IconWithText, { IconElement: _jsx(ErrorIcon, {}), text: t('portfolio.create_portfolio.steps.error.creation_error.title', { ns: 'common' }) }), _jsx(ErrorDescription, { children: t('portfolio.create_portfolio.steps.error.creation_error.text', { ns: 'common' }) }), _jsx(ErrorHint, { children: Trans({
                            i18nKey: 'portfolio.create_portfolio.steps.error.creation_error.assistance_text',
                            ns: 'common',
                            components: [
                                _jsx(Link, { href: 'mailto:"support@endrex.net"' }, 'support-team-link'),
                            ],
                        }) })] })), buttons: (_jsx(Button, { size: 'large', onClick: () => goToNextStep(), fullWidth: true, children: t('portfolio.create_portfolio.steps.error.portfolio_limit.continue_btn', { ns: 'common' }) })) }) }));
};
export default CreationError;

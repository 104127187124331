import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Wizard } from '@shared/ui/misc/Wizard';
import { useAppSelector } from '@app/store/Hooks';
import SelectExchange from './steps/SelectExchange';
import SelectWallet from './steps/SelectWallet';
import PortfolioSettings from './steps/PortfolioSettings';
import Success from './steps/Success';
import ErrorLimit from './steps/ErrorLimit';
import CreationError from './steps/CreationError';
import EnterKey from './steps/EnterKey';
const CreatePortfolioSidebar = ({ handleClose }) => {
    const { sidebarFormValues } = useAppSelector(state => state.exchange);
    return (_jsxs(Wizard, { initialStep: sidebarFormValues &&
            typeof (sidebarFormValues === null || sidebarFormValues === void 0 ? void 0 : sidebarFormValues.defaultStep) === 'number' ?
            sidebarFormValues.defaultStep : undefined, children: [_jsx(SelectExchange, { handleClose: handleClose }), _jsx(EnterKey, { handleClose: handleClose }), _jsx(SelectWallet, { handleClose: handleClose }), _jsx(PortfolioSettings, { handleClose: handleClose }), _jsx(Success, { handleClose: handleClose }), _jsx(ErrorLimit, { handleClose: handleClose }), _jsx(CreationError, { handleClose: handleClose })] }));
};
export default CreatePortfolioSidebar;

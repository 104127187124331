import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Button from '@shared/ui/buttons/Button';
import { ExchangeSidebar, getFormInitValues, resetFormValues } from '@entities/exchange';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import CreatePortfolioSidebar from '../CreatePortfolio';
const CreatePortfolioButton = (props) => {
    const dispatch = useDispatch();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const onClick = () => {
        dispatch(getFormInitValues(ExchangeSidebar.CreatePortfolio));
        setIsSidebarOpen(true);
    };
    const handleClose = () => {
        setIsSidebarOpen(false);
        dispatch(resetFormValues());
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            , Object.assign({}, props, { onClick: onClick })), _jsx(Sidebar, { isOpen: isSidebarOpen, children: _jsx(CreatePortfolioSidebar, { handleClose: handleClose }) })] }));
};
export default CreatePortfolioButton;

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import IconWithText from '@shared/ui/display/IconWIthText';
import { StepContainer } from '@shared/ui/sidebars/shared';
import { ErrorIcon } from '@shared/ui/sidebars/icons';
import Button from '@shared/ui/buttons/Button';
import { BodyMedium } from '@src/components/styled/Typography';
import usePortfolioCreate from '../usePortfolioCreate';
const ErrorDescription = styled(BodyMedium)(props => ({
    maxWidth: 400,
    textAlign: 'center',
    marginTop: props.theme.spacing_sizes.m,
    marginBottom: props.theme.spacing_sizes.l,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
}));
const ErrorHint = styled(ErrorDescription)(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 7,
}));
const ErrorLimit = ({ handleClose }) => {
    const { t } = useTranslation();
    const { goToNextStep } = usePortfolioCreate({ handleClose });
    return (_jsx(SidebarInner, { cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepContainer, { content: (_jsxs(_Fragment, { children: [_jsx(IconWithText, { IconElement: _jsx(ErrorIcon, {}), text: t('portfolio.create_portfolio.steps.error.portfolio_limit.title', { ns: 'common' }) }), _jsx(ErrorDescription, { children: t('portfolio.create_portfolio.steps.error.portfolio_limit.description', { ns: 'common' }) }), _jsx(ErrorHint, { children: t('portfolio.create_portfolio.steps.error.portfolio_limit.hint', { ns: 'common' }) }), _jsx(Button, { children: t('portfolio.create_portfolio.steps.error.portfolio_limit.get_enterprise_btn', { ns: 'common' }) })] })), buttons: (_jsx(Button, { size: 'large', onClick: () => goToNextStep(), fullWidth: true, children: t('portfolio.create_portfolio.steps.error.portfolio_limit.continue_btn', { ns: 'common' }) })) }) }));
};
export default ErrorLimit;
